import QueryString from 'qs';
import { useLocation, useRouteMatch } from 'react-router-dom';
import Leaderboard from '../../components/Leaderboard/Leaderboard';
import useLeaderboard from '../../hooks/useLeaderboard';

export default function Contest() {
  const match = useRouteMatch<{
    contestNumber: string;
  }>();
  const { contestNumber } = match.params;
  const location = useLocation();
  const querystring = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  const maxPositions = querystring.max_positions ? +querystring.max_positions : undefined;
  const data = useLeaderboard({ contestNumber, maxPositions });

  return (
    <Leaderboard data={data} />
  );
}