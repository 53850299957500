import { Switch, Route } from 'react-router-dom';
import Contest from '../Contest/Contest';
import NotFound from '../NotFound/NotFound';
import Tournament from '../Tournament/Tournament';

export default function App() {
  return (
    <Switch>
      <Route exact path="/contest/:contestNumber" component={Contest} />
      <Route exact path="/tournament/:tournamentNumber" component={Tournament} />
      <Route component={NotFound} />
    </Switch>
  );
}
