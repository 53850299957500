import QueryString from 'qs';
import { useLocation, useRouteMatch } from 'react-router-dom';
import Leaderboard from '../../components/Leaderboard/Leaderboard';
import useLeaderboard from '../../hooks/useLeaderboard';

export default function Tournament() {
  const match = useRouteMatch<{
    tournamentNumber: string;
  }>();
  const { tournamentNumber } = match.params;
  const location = useLocation();
  const querystring = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  const maxPositions = querystring.max_positions ? +querystring.max_positions : undefined;
  const data = useLeaderboard({ tournamentNumber, maxPositions });

  return (
    <Leaderboard data={data} />
  );
}