import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { LeaderboardResponse, User } from '../components/Leaderboard/types';

const DEFAULT_LEADERS_AMOUNT = 8;
const DEFAULT_PAGINATION_AMOUNT = 10;
const DEFAULT_INTERVAL_TIMER = 15000;

function useLeaderboard({
  contestNumber = undefined, 
  tournamentNumber = undefined,
  maxPositions,
}: {
  contestNumber?: string;
  tournamentNumber?: string;
  maxPositions?: number;
}) {
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [leaders, setLeaders] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [leaderboard, setLeaderboard] = useState<LeaderboardResponse>();

  const fetchLeaderboard = useCallback(async () => {
    const response: AxiosResponse<LeaderboardResponse> = await axios.get(`${process.env.REACT_APP_API_URL}/public`, {
      params: {
        contestNumber,
        tournamentNumber,
        maxPositions,
      },
    });

    return response.data;
  }, [contestNumber, tournamentNumber, maxPositions]);

  useEffect(() => {
    async function fetch() {
      const response = await fetchLeaderboard();
      setLeaderboard(response);
      setLoading(false);

      if (response.timestamp !== leaderboard?.timestamp) {
        const newOffset = DEFAULT_LEADERS_AMOUNT;
        setOffset(newOffset);
        setLeaders(response.records.slice(0, DEFAULT_LEADERS_AMOUNT))
        setUsers(response.records.slice(newOffset, newOffset + DEFAULT_PAGINATION_AMOUNT))
      }
    }

    if (!leaderboard) {
      fetch();
    } else {
      const interval = setInterval(fetch, 60000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [leaderboard, fetchLeaderboard]);

  useEffect(() => {
    function updateOffset() {
      if (leaderboard) {
        if (offset + DEFAULT_PAGINATION_AMOUNT >= leaderboard?.totalAvailable) {
          setOffset(DEFAULT_LEADERS_AMOUNT);
          setUsers(leaderboard.records.slice(DEFAULT_LEADERS_AMOUNT, DEFAULT_LEADERS_AMOUNT + DEFAULT_PAGINATION_AMOUNT))
        } else {
          setOffset((prevOffset) => {
            const newOffset = prevOffset + DEFAULT_PAGINATION_AMOUNT;
            setUsers(leaderboard.records.slice(newOffset, newOffset + DEFAULT_PAGINATION_AMOUNT))
            return newOffset;
          });
        }
      }
    }

    const interval = setInterval(updateOffset, +(process.env.REACT_APP_INTERVAL_TIMER || DEFAULT_INTERVAL_TIMER));

    return () => {
      clearInterval(interval);
    };
  }, [leaderboard, users, offset]);

  return {
    loading,
    users,
    leaderboard,
    leaders
  };
}

export default useLeaderboard;