import bg from './bg.jpg';
import ntra from './ntra.png';
import caesars from './caesars.png';
import tvg from './tvg.png';
import clsx from 'clsx';
import dayjs from 'dayjs';
import LeaderItem from './LeaderItem';
import styles from './Leaderboard.module.css';
import UserItem from './UserItem';
import { LeaderboardResponse, User } from './types';
import loadingGif from './loading.gif';

export default function Leaderboard({
  data,
}: {
  data: {
    users: User[],
    leaderboard?: LeaderboardResponse,
    loading: boolean;
    leaders: User[]
  };
}) {
  const { users = [], leaderboard, loading, leaders = [] } = data;

  return (
    <div className={styles.container} style={{ background: `url(${bg})`}}>
      {
        leaderboard ? (
          <div className={styles.content}>
            <header className={styles.header}>
              <div className={clsx(styles.imgContainer, styles.brand)}>
                <img src={caesars} alt="Caesars Sportsbook" className={styles.img} />
              </div>
              <div className={styles.titleContainer}>
                <div className={clsx(styles.imgContainer, styles.ntra)}>
                  <img src={ntra} alt="National Thoroughbred Racing Association" className={styles.img} />
                </div>
                <div className={styles.subtitleContainer}>
                  <span className={styles.strikethrough} />
                  <h2 className={styles.subtitle}>{leaderboard.description}</h2>
                  <span className={styles.strikethrough} />
                </div>
                {
                  leaderboard.timestamp ? (
                    <span className={styles.timestamp}>last updated {dayjs
                      .duration(dayjs(leaderboard.timestamp).diff(dayjs()), 'milliseconds')
                      .humanize(true)} ({dayjs(leaderboard.timestamp).format('MM/DD/YYYY hh:mmA')})</span>
                  ) : null
                  }
              </div>
              <div className={clsx(styles.imgContainer, styles.tvg)}>
                <img src={tvg} alt="TVG" className={styles.img} />
              </div>
            </header>
            <div className={styles.leaderboard}>
              <ul className={styles.list}>
                {
                  leaders.map((leader) => (
                    <li key={leader.playerId}>
                      <LeaderItem user={leader} />
                    </li>
                  ))
                }
              </ul>
              {
                loading ? (
                  <div className={styles.loadingContainer}>
                    <img src={loadingGif} alt="Loading" />
                  </div>
                ) : (
                  <ul className={styles.list}>
                    {
                      users.map((user) => (
                        <li key={user.playerId}>
                          <UserItem user={user} />
                        </li>
                      ))
                    }
                  </ul>
                )
              }
            </div>
          </div>
        ) : null
      }
    </div>
  );
}