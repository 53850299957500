import clsx from 'clsx';
import { User } from './types';
import styles from './LeaderItem.module.css';

type Props = {
  user: User;
};

export default function LeaderItem({ user }: Props) {
  const positionChange = user.previousPosition - user.position;
  return (
    <div className={styles.container}>
      <span className={styles.position}>{user.position}</span>
      <div className={styles.detailsContainer}>
        <div>
          <span className={styles.name}>{user.alias}</span>
          {
            user.previousPosition !== 0 && positionChange !== 0 ? <span className={clsx(styles.orderChange, {
              [styles.positive]: user.position < user.previousPosition,
              [styles.negative]: user.position > user.previousPosition,
            })}>
              {`${user.position < user.previousPosition ? '↑' : '↓'} ${Math.abs(positionChange)}`}
            </span> : null
          }
        </div>
        <span className={styles.details}>Mandatory - {user.mandatoryCount} &middot; Optional - {user.optionalCount}</span>
      </div>
      <span className={styles.winnings}>{user.winnings}</span>
    </div>
  );
}
